<template>
  <div :style="{width:windowWidth + 'px'}">
    <van-tabs color="rgba(12, 103, 255, 1)" title-active-color="rgba(12, 103, 255, 1)" @click="onClick">
      <van-tab title="未发布">

        <div v-if="!displayA">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div style="margin:10px">
              <div v-for="(item,index) in data" :key="index" :style="{width:(windowWidth-20) + 'px'}" class="margin-bottom boxshadow" @click="jumtodetail(item)">
                <van-tag color="#f1f1f1" text-color="#959595" size="large" style="position: absolute;margin:9px">{{ item.status }}</van-tag>
                <div class="flex">
                  <div style="width:50%" class="pic-content">
                    <img style="width:100%;height:150px" :src="'http://cdn.dreamacro.com/' + item.banner+'?imageView2/2/w/400'">
                    <div class="imgshadow-word">
                      {{ item.launch_org }}
                    </div>
                  </div>
                  <!-- <div :style="{width:(windowWidth-10)/2 + 'px'}" class="card-content breakword"> -->
                  <div style="width:50%;height:150px" class="card-content">
                    <div class="client-item-row-phone">
                      <div class="breakword">参赛选手：{{ item.player_count }}</div>
                      <div class="breakword">累计票数：{{ item.vote_count }}</div>
                      <div class="breakword">累计访问：{{ item.view_count }}</div>
                      <!-- <div class="breakword">当前状态：{{ item.status }}</div> -->
                    </div>
                    <!-- <van-icon name="add" color="#0081ff" class="add-icon" @click.stop="jumptosign" /> -->
                    <div class="client-item-options">
                      <div v-if="item.activity_status === 1" class="options-item" :data-phone="item.contact_phone" @click.stop="submitrelease(item,3)">
                        <div class="icon-sizebg flex" style="background-color:#01B578">
                          <img class="icon-size" src="http://static.dreamacro.com/publicimg%2Fvote_publish.png">
                        </div>
                        <div>
                          发布
                        </div>
                      </div>
                      <div v-if="item.signup_status === 1" class="options-item" :data-phone="item.contact_phone" @click.stop="jumptosign(item.id)">
                        <div class="icon-sizebg flex" style="background-color:#FFB211">
                          <img class="icon-size" src="http://static.dreamacro.com/publicimg%2Fvote_enroll.png">
                        </div>
                        <div>
                          报名
                        </div>
                      </div>
                      <div v-if="item.activity_status === 1" class="options-item" :data-phone="item.contact_phone" @click.stop="jumptoedit(item)">
                        <div class="icon-sizebg flex" style="background-color:#2981FC">
                          <img class="icon-size" src="http://static.dreamacro.com/publicimg%2Fvote_edit.png">
                        </div>
                        <div>
                          编辑
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </van-list>
        </div>
        <div v-else>
          <van-empty description="记得新增哦~" />
        </div>
      </van-tab>
      <van-tab title="已发布">
        <div v-if="!displayB">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoadalready"
          >
            <div style="margin:10px">
              <div v-for="(item,index) in list" :key="index" :style="{width:(windowWidth-20) + 'px'}" class="margin-bottom boxshadow" @click="jumtodetail(item)">
                <van-tag color="#fde6d2" text-color="#959595" size="large" style="position: absolute;margin:9px">{{ item.status }}</van-tag>
                <div class="flex">
                  <div style="width:50%" class="pic-content">
                    <img style="width:100%;height:150px" :src="'http://cdn.dreamacro.com/' + item.banner +'?imageView2/2/w/400'">
                    <div class="imgshadow-word">
                      {{ item.launch_org }}
                    </div>
                  </div>
                  <div style="width:50%;height:150px" class="card-content">
                    <div class="client-item-row-phone">
                      <div class="breakword">参赛选手：{{ item.player_count }}</div>
                      <div class="breakword">累计票数：{{ item.vote_count }}</div>
                      <div class="breakword">累计访问：{{ item.view_count }}</div>
                      <!-- <div class="breakword">当前状态：{{ item.status }}</div> -->
                    </div>
                    <div class="client-item-options">
                      <div class="options-item" :data-phone="item.contact_phone" @click.stop="submitrelease(item,6)">
                        <div class="icon-sizebg flex" style="background-color:#FF622C">
                          <img class="icon-size" src="http://static.dreamacro.com/publicimg%2Fvote_undercarriage.png">
                        </div>
                        <div>
                          下架
                        </div>
                      </div>
                      <div v-if="item.signup_status === 1" class="options-item" :data-phone="item.contact_phone" @click.stop="jumptosign(item.id)">
                        <div class="icon-sizebg flex" style="background-color:#FFB211">
                          <img class="icon-size" src="http://static.dreamacro.com/publicimg%2Fvote_enroll.png">
                        </div>
                        <div>
                          报名
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
        <div v-else>
          <van-empty description="记得新增哦~" />
        </div>
      </van-tab>
    </van-tabs>
    <van-icon color="rgba(12, 103, 255, 1)" class="roundadd" name="add-o" @click.stop="jumptoset" />
  </div>
</template>

<script>
import { getopenid, getvotemanage, changevotemanage } from '@/api/votemange'

export default {
  data() {
    return {
      openid: '',
      data: [],
      list: [],
      loading: false,
      finished: false,
      displayA: false,
      displayB: false,
      pageObj: {
        page_num: 0,
        page_size: 10,
        status: 1
      },
      windowWidth: document.documentElement.clientWidth, // 实时屏幕宽度
      baseSrc: 'http://cdn.dreamacro.com/'
    }
  },

  mounted() {
    // history.pushState(null, null, document.URL)
    // window.addEventListener('popstate', function() {
    //   // 监听浏览器的返回事件
    //   history.pushState(null, null, document.URL)
    // })

    this.getscreen()
    const openid = localStorage.getItem('openid')

    if (openid) {
      this.openid = openid
      this.upadatapage(openid)
    } else {
      this.init()
    }
  },
  methods: {
    async init() {
      this.code = ''
      // var appid = 'wxa9b1061989663c5c' // 公众号
      var appid = 'wx79d1a256e0353320' // 线上公众号
      var local = window.location.href
      this.code = this.getUrlCode().code
      if (this.code === null || this.code === '' || this.code === undefined) {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appid + '&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_userinfo&state=ok#wechat_redirect'
      } else {
        this.openID(this.code)
      }
    },
    async openID(code) {
      localStorage.setItem('code', code)
      const res = await getopenid({ wechat_code: code })
      if (res.code === 0) {
        this.openid = res.data
        localStorage.setItem('openid', res.data)
        this.onLoad()
      }
    },
    async upadatapage(openid) {
      this.onLoad(openid)
    },
    getUrlCode() { // 截取url中的code方法
      var url = location.search
      this.winUrl = url
      // eslint-disable-next-line no-new-object
      var theRequest = new Object()
      if (url.indexOf('?') !== -1) {
        var str = url.substr(1)
        var strs = str.split('&')
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1])
        }
      }
      return theRequest
    },
    async onLoad() {
      if (this.openid) {
        this.pageObj.page_num += 1
        const res = await getvotemanage({ ...this.pageObj, open_id: this.openid })
        if (res.data.total === 0) {
          this.displayA = true
        }
        res.data.items.forEach(item => {
          item.banner = item.banner.split(',')[0]
        })
        const arr = res.data.items
        this.data.push(...arr)
        this.loading = false
        console.log('dasdsad', arr)
        if (arr.length === 0) {
          this.finished = true
        }
      }
    },
    async onLoadalready() {
      this.pageObj.page_num += 1
      const res = await getvotemanage({ ...this.pageObj, open_id: this.openid })
      if (res.data.total === 0) {
        this.displayB = true
      }
      res.data.items.forEach(item => {
        item.banner = item.banner.split(',')[0]
      })
      const arr = res.data.items
      this.list.push(...arr)
      this.loading = false
      if (arr.length === 0) {
        this.finished = true
      }
    },
    getscreen() {
      window.screenWidth = document.body.clientWidth
      this.screenWidth = window.screenWidth
      console.log(this.screenWidth)
    },
    onClick(name) {
      if (name === 0) {
        this.data = []
        this.pageObj.status = 1
        this.pageObj.page_num = 0
        this.onLoad()
      } else {
        this.list = []
        this.pageObj.status = 2
        this.pageObj.page_num = 0
        this.onLoadalready()
      }
    },
    submitrelease(row, status) {
      console.log(row)
      this.$dialog.alert({
        title: '提示',
        message: `是否${status === 3 ? '发布' : '下架'}投票？ `,
        showCancelButton: true
      }).then(async() => {
        const res = await changevotemanage(row.id, status, this.openid)
        if (res.code === 0) {
          this.$toast.success(`${status === 3 ? '发布' : '下架'}成功 `)
          if (status === 3) {
            this.data = []
            this.pageObj.status = 1
            this.pageObj.page_num = 0
            this.onLoad()
          } else {
            this.list = []
            this.pageObj.status = 2
            this.pageObj.page_num = 0
            this.onLoadalready()
          }
        }
      })
    },
    jumptosign(id) {
      this.$router.push({ name: 'Votesign', query: { id: id }})
    },
    jumtodetail(row) {
      this.$router.push({ name: 'Votepage', query: { id: 0, originid: row.id, title: row.launch_org }})
    },
    jumptoset() {
      this.$router.push({ name: 'Voteset' })
    },
    jumptoedit(row) {
      // const id = JSON.stringify(row)
      // this.$router.push({ name: 'Voteset', query: { id: row.id }})
      this.$router.push({ name: 'Voteset', params: { id: row.id }})
    }
  }
}
</script>

<style scoped>
.boxshadow {
    box-shadow: 0px 0px 15px 0px rgba(0, 19, 64, 0.1);
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
}
.card-content{
    padding: 10px 10px 10px 10px;
}
.pic-content{
  padding: 10px 10px 10px 10px;
}
.margin-bottom{
  margin-bottom: 15px;
}
.flex{
  display: flex;
}
.client-item-row-custom {
    font-size: 17px;
    font-weight: 400;
    color: #000000;
    line-height: 25px;
}
.client-item-row-phone {
    font-size: 15px;
    font-weight: 400;
    color: #959595;
    height: 80px;

}
.breakword{
  word-wrap:break-word;
  display:-webkit-box;
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis;/*隐藏后添加省略号*/
  /* white-space: nowrap; */
  -webkit-box-orient:vertical;
  -webkit-line-clamp:3;
}
.add-icon{
  font-size:38px;
  padding: 20px 14px 0px 0;
}
.topboxtext{
  padding:15px 15px 5px 15px
}
.bottom-button{
  padding:10px 25px
}
.imgshadow-word {
    background: #000;
    opacity: 0.6;
    color: #fff;
    /* width: 90%; */
    height: 36px;
    margin-top: -56px;
    /* left: 0px; */
    padding: 8px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
	.client-item-options {
		display: flex;
		margin-top: 18px;
	}
  .icon-size {
		width: 20px;
		height: 20px;
    margin: auto;
	}
  .icon-sizebg{
    width: 30px;
		height: 30px;
    border-radius: 50%;
  }
  .options-item {
		padding: 6px 14px 10px 0;
		font-size: 12px;
		text-align: center;
		color: #959595;
	}
  .roundadd {
		font-size: 48px;
		position: fixed;
		bottom: 10px;
		right: 10px;
	}
  .flex{
    display: flex;
  }

</style>
